<template>
  <div class="login_wrap">
    <div class="login_header">
      <div class="logo">
        <span>易恒开放平台</span>
      </div>
      <div class="dosc">2020年9月更新</div>
    </div>

    <div class="login_content">
      <div class="left">
        <p>数据可视化深度融合，创造数字化未来</p>
      </div>
      <div class="right">
        <div>
          <span :class="{ borderB: styles === 1 }" @click="WeChat(1)"
            >微信扫码登录</span
          >
          <span></span>
          <span :class="{ borderB: styles === 2 }" @click="WeChat(2)"
            >企业微信登录</span
          >
        </div>
        <div v-if="styles == 1">
          <div class="code"><img :src="codeUrl" alt /></div>
          <div class="text">
            <span>请使用<span class="color_b">微信</span>扫描二维码登录</span>
          </div>
          <div class="text">
            <span>{{ indate }}</span>
            <span>秒后自动刷新</span>
          </div>
        </div>
        <div v-if="styles == 2">
          <iframe :src="qewxsrc" class="qywx_login"></iframe>
        </div>
      </div>
    </div>

    <div class="login_foot">
      <span> Copyright © 2018-{{new Date().getFullYear()}} ECMOHO. 易恒健康-数据中心 版权所有 </span>
    </div>
  </div>
</template>

<script>
import http from "axios";
import { Promise, async } from "q";
export default {
  data() {
    return {
      styles: 1,
      qewxLogin: {},
      qewxsrc: "",
      codeUrl: "",
      indate: 120, //倒计时总时间
      indateJiShiQi: null,
      timer: [],
      isDone: false,
      all: null,
      allTime: 180, //多少时间后停止请求接口 超时断开
      myToken: "", //
      isSendSso: false,
      jiShiQi: null,
    };
  },
  mounted() {
    this.getCode();
  },
  methods: {
    WeChat(val) {
      clearInterval(this.indateJiShiQi);
      clearInterval(this.jiShiQi);
      this.styles = val;
      this.getCode();
    },
    getCode() {
      let url = window.location.href.split("#")[0];
      let _url = "";
      if (this.styles == 1) {
        _url = "/qrcode/user/manage/vx/?app_domain=" + url;
      } else {
        _url = "qrcode/user/getCompanyShortUrl?app_domain=" + url;
      }
      let getUrl = () => {
        http
          .get(_url, { timeout: 2000 })
          .then(({ data }) => {
            let res = data;
            if (this.styles == 1) {
              this.codeUrl = res.data.url;
              this.lastTime(res.data.scene_id);
            } else {
              this.qewxLogin = res.data;
              this.qewxsrc = `https://sso.ecmoho.com/#/qewx_login?agentid=${res.data.agentid}&appid=${res.data.appid}&redirect_uri=${res.data.redirect_uri}&state=${res.data.state}`;
              this.lastTime(res.data.state);
            }
            this.startInterval();
          })
          .catch((err) => {
            setTimeout(() => {
              getUrl();
            }, 1000);
          });
      };
      getUrl();
    },
    isScanCode(id) {
      var datas;
      if (this.styles == 1) {
        datas = {
          scene_id: id,
        };
      } else {
        datas = {
          qiyework_scene_id: id,
        };
      }
      this.jiShiQi = setInterval(() => {
        http
          .get("/qrcode/user/vx/user_info/", {
            params: datas,
          })
          .then(async (res) => {
            //扫码成功
            if (res.data.code == 200) {
              clearInterval(this.jiShiQi);
              clearInterval(this.indateJiShiQi);
              this.setUserInfo(res);
            }
          })
          .catch((e) => {});
      }, 2000);
    },
    lastTime(id) {
      this.isScanCode(id); //开始检查是否扫码
    },
    //刷新二维码倒计时启动
    startInterval() {
      this.indate = 120;
      this.indateJiShiQi = setInterval(() => {
        if (this.indate <= 0) {
          clearInterval(this.indateJiShiQi);
          clearInterval(this.jiShiQi);
          this.getCode();
          return;
        }
        this.indate--;
      }, 1000);
    },
    // 取到用户信息  开始操作
    setUserInfo(res) {
      let val = res.data.data;
      let data = {
        name: val.user_name,
        token: val.token,
        unite_field: val.unite_field,
        email: val.email,
        group: val.group,
        nick_name: val.nick_name,
        status: val.status,
        user_number: val.user_number,
        vx_id: val.vx_id,
      };
      this.$cookies.set('token', val.token, -1, "/",'ecmoho.com');
      this.$cookies.set('token', val.token);
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.login_wrap {
  height: 100%;
  background: #333;
  position: relative;
  & > .login_header {
    display: flex;
    height: 148px;
    justify-content: space-around;
    background-color: rgba(78, 94, 107, 0.6);
    .logo {
      height: auto;
      line-height: 64px;
      color: white;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      display: flex;
      align-items: center;
      svg {
        color: #fff;
        height: 26px;
        width: 26px;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
    .dosc {
      color: #b4beca;
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 2px;
      margin-left: 180px;
    }
  }
  & > .login_content {
    width: 100%;
    min-height: calc(100% - 351px);
    display: flex;
    align-items: center;
    justify-content: center;
    & > .left {
      & > p {
        font-size: 30px;
        color: #fff;
        letter-spacing: 2px;
      }
      & > ul {
        color: #cdd1d8;
        text-align: left;
        line-height: 30px;
        font-size: 14px;
        margin-top: 40px;
      }
    }
    & > .right {
      width: 340px;
      margin-left: 100px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      & > div:nth-child(1) {
        height: 40px;
        line-height: 63px;
        text-align: center;
        margin: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dcdde4;
        & > span:nth-child(1) {
          width: 48%;
          height: 40px;
          float: left;
          font-size: 16px;
          font-weight: 600;
          display: inline-block;
          color: #999999;
          line-height: 22px;
        }
        & > span:nth-child(2) {
          width: 1px;
          height: 30px;
          background: #dddddd;
          display: inline-block;
          margin-left: 2%;
          float: left;
          margin-top: -5px;
        }
        & > span:nth-child(3) {
          width: 48%;
          height: 40px;
          font-size: 16px;
          float: right;
          font-weight: 600;
          color: #999999;
          line-height: 22px;
          display: inline-block;
        }
      }

      & > div:nth-child(2) {
        background-color: #fff;
        height: 340px;
        overflow: hidden;
        & > .code {
          background-color: #fff;
          width: 180px;
          height: 180px;
          margin: 0 auto 34px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
        & > .text {
          text-align: center;
          color: #333333;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      & > div:nth-child(3) {
        background-color: #fff;
        height: 340px;
        overflow: hidden;
        & > .code {
          background-color: #fff;
          width: 180px;
          height: 180px;
          margin: 0 auto 34px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
        & > .text {
          text-align: center;
          color: #333333;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
  & > .login_foot {
    width: 100%;
    height: 203px;
    background-color: rgba(78, 94, 107, 0.5);
    text-align: center;
    overflow: hidden;
    & > span {
      display: inline-block;
      margin-top: 50px;
      color: #acb4c0;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}
.borderB {
  border-bottom: 3px solid #409eff;
  border-radius: 2px;
  color: #409eff !important;
}
.qywx_login {
  height: 100%;
  width: 100%;
  border: none !important;
}
</style>
